/* tslint:disable */
/* eslint-disable */
/**
 * Mollie - Lightspeed Payments App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { LightspeedCheckoutIssuer } from '../models';
// @ts-ignore
import { LightspeedCheckoutSelectedIssuer } from '../models';
// @ts-ignore
import { LightspeedCheckoutSettings } from '../models';
/**
 * CheckoutApi - axios parameter creator
 * @export
 */
export const CheckoutApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves profile settings for the shop
         * @param {string} shopId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lightspeedShopIdSettingsGet: async (shopId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shopId' is not null or undefined
            assertParamExists('lightspeedShopIdSettingsGet', 'shopId', shopId)
            const localVarPath = `/lightspeed/{shop_id}/settings`
                .replace(`{${"shop_id"}}`, encodeURIComponent(String(shopId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves mollie issuers for given payment method.
         * @param {string} paymentMethod 
         * @param {string} shopId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        molliePaymentMethodIssuersShopIdGet: async (paymentMethod: string, shopId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentMethod' is not null or undefined
            assertParamExists('molliePaymentMethodIssuersShopIdGet', 'paymentMethod', paymentMethod)
            // verify required parameter 'shopId' is not null or undefined
            assertParamExists('molliePaymentMethodIssuersShopIdGet', 'shopId', shopId)
            const localVarPath = `/mollie/{payment_method}/issuers/{shop_id}`
                .replace(`{${"payment_method"}}`, encodeURIComponent(String(paymentMethod)))
                .replace(`{${"shop_id"}}`, encodeURIComponent(String(shopId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a transaction with the selected issuer and quote ID
         * @param {MolliePaymentMethodIssuersShopIdPostPaymentMethodEnum} paymentMethod 
         * @param {string} shopId 
         * @param {LightspeedCheckoutSelectedIssuer} [lightspeedCheckoutSelectedIssuer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        molliePaymentMethodIssuersShopIdPost: async (paymentMethod: MolliePaymentMethodIssuersShopIdPostPaymentMethodEnum, shopId: string, lightspeedCheckoutSelectedIssuer?: LightspeedCheckoutSelectedIssuer, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentMethod' is not null or undefined
            assertParamExists('molliePaymentMethodIssuersShopIdPost', 'paymentMethod', paymentMethod)
            // verify required parameter 'shopId' is not null or undefined
            assertParamExists('molliePaymentMethodIssuersShopIdPost', 'shopId', shopId)
            const localVarPath = `/mollie/{payment_method}/issuers/{shop_id}`
                .replace(`{${"payment_method"}}`, encodeURIComponent(String(paymentMethod)))
                .replace(`{${"shop_id"}}`, encodeURIComponent(String(shopId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lightspeedCheckoutSelectedIssuer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CheckoutApi - functional programming interface
 * @export
 */
export const CheckoutApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CheckoutApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves profile settings for the shop
         * @param {string} shopId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lightspeedShopIdSettingsGet(shopId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LightspeedCheckoutSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lightspeedShopIdSettingsGet(shopId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CheckoutApi.lightspeedShopIdSettingsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves mollie issuers for given payment method.
         * @param {string} paymentMethod 
         * @param {string} shopId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async molliePaymentMethodIssuersShopIdGet(paymentMethod: string, shopId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LightspeedCheckoutIssuer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.molliePaymentMethodIssuersShopIdGet(paymentMethod, shopId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CheckoutApi.molliePaymentMethodIssuersShopIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a transaction with the selected issuer and quote ID
         * @param {MolliePaymentMethodIssuersShopIdPostPaymentMethodEnum} paymentMethod 
         * @param {string} shopId 
         * @param {LightspeedCheckoutSelectedIssuer} [lightspeedCheckoutSelectedIssuer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async molliePaymentMethodIssuersShopIdPost(paymentMethod: MolliePaymentMethodIssuersShopIdPostPaymentMethodEnum, shopId: string, lightspeedCheckoutSelectedIssuer?: LightspeedCheckoutSelectedIssuer, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.molliePaymentMethodIssuersShopIdPost(paymentMethod, shopId, lightspeedCheckoutSelectedIssuer, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CheckoutApi.molliePaymentMethodIssuersShopIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CheckoutApi - factory interface
 * @export
 */
export const CheckoutApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CheckoutApiFp(configuration)
    return {
        /**
         * Retrieves profile settings for the shop
         * @param {string} shopId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lightspeedShopIdSettingsGet(shopId: string, options?: any): AxiosPromise<LightspeedCheckoutSettings> {
            return localVarFp.lightspeedShopIdSettingsGet(shopId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves mollie issuers for given payment method.
         * @param {string} paymentMethod 
         * @param {string} shopId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        molliePaymentMethodIssuersShopIdGet(paymentMethod: string, shopId: string, options?: any): AxiosPromise<Array<LightspeedCheckoutIssuer>> {
            return localVarFp.molliePaymentMethodIssuersShopIdGet(paymentMethod, shopId, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a transaction with the selected issuer and quote ID
         * @param {MolliePaymentMethodIssuersShopIdPostPaymentMethodEnum} paymentMethod 
         * @param {string} shopId 
         * @param {LightspeedCheckoutSelectedIssuer} [lightspeedCheckoutSelectedIssuer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        molliePaymentMethodIssuersShopIdPost(paymentMethod: MolliePaymentMethodIssuersShopIdPostPaymentMethodEnum, shopId: string, lightspeedCheckoutSelectedIssuer?: LightspeedCheckoutSelectedIssuer, options?: any): AxiosPromise<void> {
            return localVarFp.molliePaymentMethodIssuersShopIdPost(paymentMethod, shopId, lightspeedCheckoutSelectedIssuer, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CheckoutApi - object-oriented interface
 * @export
 * @class CheckoutApi
 * @extends {BaseAPI}
 */
export class CheckoutApi extends BaseAPI {
    /**
     * Retrieves profile settings for the shop
     * @param {string} shopId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public lightspeedShopIdSettingsGet(shopId: string, options?: RawAxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).lightspeedShopIdSettingsGet(shopId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves mollie issuers for given payment method.
     * @param {string} paymentMethod 
     * @param {string} shopId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public molliePaymentMethodIssuersShopIdGet(paymentMethod: string, shopId: string, options?: RawAxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).molliePaymentMethodIssuersShopIdGet(paymentMethod, shopId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a transaction with the selected issuer and quote ID
     * @param {MolliePaymentMethodIssuersShopIdPostPaymentMethodEnum} paymentMethod 
     * @param {string} shopId 
     * @param {LightspeedCheckoutSelectedIssuer} [lightspeedCheckoutSelectedIssuer] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public molliePaymentMethodIssuersShopIdPost(paymentMethod: MolliePaymentMethodIssuersShopIdPostPaymentMethodEnum, shopId: string, lightspeedCheckoutSelectedIssuer?: LightspeedCheckoutSelectedIssuer, options?: RawAxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).molliePaymentMethodIssuersShopIdPost(paymentMethod, shopId, lightspeedCheckoutSelectedIssuer, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const MolliePaymentMethodIssuersShopIdPostPaymentMethodEnum = {
    Ideal: 'ideal',
    Creditcard: 'creditcard'
} as const;
export type MolliePaymentMethodIssuersShopIdPostPaymentMethodEnum = typeof MolliePaymentMethodIssuersShopIdPostPaymentMethodEnum[keyof typeof MolliePaymentMethodIssuersShopIdPostPaymentMethodEnum];

import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import Icon from '../Icon/Icon'

export function SortableItem(props: any) {
    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({ id: props.id })

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    }

    return (
        <div style={style} ref={setNodeRef}>
            <div className="flex items-center grow">
                {props.isArrangingMethods && (
                    <div {...attributes} {...listeners}>
                        <Icon classList="w-8 h-8" name="balls" />
                    </div>
                )}
                {props.children}
            </div>
        </div>
    )
}

import React from 'react'
import { Switch } from '@headlessui/react'
import { classNames } from '../../utils/classNames'
import Span from '../Typography/Span'

interface RadioProps {
    enabled: boolean
    setEnabled: (enabled: boolean) => void
    label?: string
    statusColor?: string
    className?: string
    loading?: boolean
}

const Radio: React.FC<RadioProps> = ({
    enabled,
    setEnabled,
    label,
    statusColor,
    className,
    loading = false,
}) => {
    let enabledColor = 'bg-brand-primary border-brand-primary'
    if (statusColor) {
        enabledColor = statusColor
    }
    return (
        <Switch.Group
            as="div"
            className={classNames(
                className,
                'flex items-center',
                loading ? 'animate-pulse' : '',
            )}
        >
            <Switch
                checked={enabled}
                onChange={setEnabled}
                className={classNames(
                    enabled ? enabledColor : 'bg-white border-black',
                    'relative inline-flex flex-shrink-0 h-4 w-8 border-2  rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
                )}
            >
                <span
                    aria-hidden="true"
                    className={classNames(
                        enabled
                            ? 'translate-x-4 bg-white'
                            : 'translate-x-1 bg-black',
                        'pointer-events-none inline-block translate-y-0.5 h-2 w-2 rounded-full shadow transform ring-0 transition ease-in-out duration-200',
                    )}
                />
            </Switch>
            {label && (
                <Switch.Label as="span" className="ml-3">
                    <Span classList="text-sm text-black">{label}</Span>
                </Switch.Label>
            )}
        </Switch.Group>
    )
}

export default Radio

import React from 'react'
import Heading, { HeadingVariant } from '../../components/Typography/Heading'

const Unauthorized: React.FC = () => {
    return (
        <div className="flex flex-col min-h-screen max-w-md p-6 justify-center m-auto">
            <Heading
                classList="text-center mb-5"
                headingVariant={HeadingVariant.BLACK}
            >
                Unauthorized
            </Heading>
            <p className="font-base text-base text-lg text-black text-opacity-60 text-center">
                Please try reconnecting from LightSpeed.
            </p>
        </div>
    )
}

export default Unauthorized

import React, { useContext, useEffect } from 'react'
import { UserContext } from '../../providers/UserProvider'

const Disconnected: React.FC = () => {
    const userContext = useContext(UserContext)

    useEffect(() => {
        userContext.setMerchant(null)
        userContext.setToken(null)
    }, [])

    return (
        <div className="flex flex-col min-h-screen max-w-md p-6 justify-center m-auto">
            <p className="font-base text-base text-lg text-black text-opacity-60 text-center">
                Your Mollie account has been disconnected as active payment
                provider, note that you will still be able to receive refund
                requests.
            </p>

            <p className="font-base text-base text-lg text-black text-opacity-60 text-center mt-4">
                You may now close this page.
            </p>
        </div>
    )
}

export default Disconnected

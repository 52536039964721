import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../providers/UserProvider'
import { useNavigate } from 'react-router-dom'
import { TransactionPollStatus } from '../../plugins/middleware-api-client'

const Processing: React.FC = () => {
    const userContext = useContext(UserContext)
    const navigate = useNavigate()
    const [attempts, setAttempts] = useState<number>(0)
    const [transactionPollStatus, setTransactionPollStatus] =
        useState<TransactionPollStatus | null>(null)
    const [delay] = useState<number>(1000)

    useEffect(() => {
        pollTransaction()
    }, [])

    useEffect(() => {
        if (attempts >= 10) {
            navigate('/error')
        } else if (attempts > 0) {
            setTimeout(pollTransaction, delay)
        }
    }, [attempts])

    const pollTransaction = async () => {
        await userContext
            .pollTransaction()
            .then((res) => setTransactionPollStatus(res))
            .catch(() => {})
        if (
            !transactionPollStatus?.redirect &&
            !transactionPollStatus?.failure
        ) {
            setAttempts((attempts) => attempts + 1)
        }
        if (transactionPollStatus?.redirect) {
            window.location.href = transactionPollStatus.redirectUrl
        }
    }

    return (
        <div className="flex items-center justify-center min-h-screen">
            <div>
                <svg
                    className="animate-spin h-12 w-12 mx-auto text-black"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                    />
                    <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                </svg>

                <h1 className="text-center text-xl mt-6">Processing payment</h1>
            </div>
        </div>
    )
}

export default Processing

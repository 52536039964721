import React from 'react'
import Heading, { HeadingVariant, Level } from './Typography/Heading'
import { useTranslation } from 'react-i18next'

const PlaceholderPaymentMethod: React.FC = () => {
    const { t } = useTranslation()
    return (
        <div className="flex-grow border border-brand-gray-200 px-3 py-2 bg-white mb-4 rounded animate-pulse">
            <div className="flex justify-between py-2">
                <div className="flex items-center">
                    <div className="rounded h-8 w-8 flex shrink bg-brand-gray-100"></div>
                    <div className="pl-3">
                        <Heading
                            headingVariant={HeadingVariant.BLACK}
                            level={Level.HEADING_6}
                        >
                            {t('common:loading')}
                        </Heading>
                    </div>
                </div>
                <div className="flex items-center"></div>
            </div>
        </div>
    )
}

export default PlaceholderPaymentMethod

import React, { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { classNames } from '../../utils/classNames'
import Label from './Label'

interface SelectProps extends React.InputHTMLAttributes<HTMLSelectElement> {
    id: string
    label?: string
    options: FormOption[]
    loading?: boolean
    error?: boolean
    selected: FormOption
    setSelected: React.Dispatch<React.SetStateAction<any>>
}

export interface FormOption {
    label: string
    value: string | null
}

const Select: React.FC<SelectProps> = ({
    label,
    id,
    options,
    selected,
    loading = false,
    error = false,
    setSelected,
    ...rest
}) => {
    const disabled: boolean =
        rest.disabled !== undefined ? rest.disabled : false
    return (
        <Listbox value={selected} disabled={disabled} onChange={setSelected}>
            {({ open }) => (
                <div className="relative">
                    {label && (
                        <Label id="test" classList="text-gray-500">
                            {label}
                        </Label>
                    )}
                    <Listbox.Button
                        className={`block py-3 pl-3 pr-7 w-full rounded-2px relative border ${
                            error
                                ? 'border-red-800'
                                : 'border-black border-opacity-10'
                        } appearance-none focus:outline-none focus:border-opacity-20 placeholder-brand-gray-700 placeholder-opacity-40 truncate  min-h-12.25 ${
                            disabled || loading ? 'bg-gray-200' : 'bg-white'
                        }`}
                    >
                        <span
                            className={`overflow-x-clip block text-left text-sm leading-3 ${
                                disabled || loading ? 'text-gray-700' : ''
                            }`}
                        >
                            {loading ? 'Loading...' : selected.label}
                        </span>

                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none text-black">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-3 w-3"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </span>
                    </Listbox.Button>

                    <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options
                            static
                            className="text-left absolute z-10 w-full md:min-w-full md:w-auto bg-white shadow-lg max-h-64 text-sm -mt-1 overflow-auto focus:outline-none sm:text-sm border border-black border-opacity-10 appearance-none focus:outline-none focus:border-opacity-20 placeholder-gray-700 placeholder-opacity-40"
                        >
                            {options.map((option) => (
                                <Listbox.Option
                                    key={option.value}
                                    className={({ active }) =>
                                        classNames(
                                            active ? 'bg-gray-100' : 'bg-white',
                                            'cursor-default select-none relative  py-3 px-4',
                                        )
                                    }
                                    value={option}
                                >
                                    {({ selected, active }) => (
                                        <>
                                            <span
                                                className={classNames(
                                                    selected
                                                        ? 'font-semibold'
                                                        : 'font-normal',
                                                    'block truncate',
                                                )}
                                            >
                                                {option.label}
                                            </span>
                                        </>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            )}
        </Listbox>
    )
}

export default Select

import React from 'react'
import { ReactSVG } from 'react-svg'
import { classNames } from '../../utils/classNames'

interface IconProps {
    name: string
    classList?: string
}

const Icon: React.FC<IconProps> = ({ name, classList }, ...rest) => {
    return (
        <ReactSVG
            src={`/assets/icons/${name}.svg`}
            className={classNames('stroke-current fill-current', classList)}
        />
    )
}

export default Icon

import React, { useContext, useEffect, useState } from 'react'
import Heading, {
    HeadingVariant,
    Level,
} from '../../../components/Typography/Heading'
import Radio from '../../../components/Form/Radio'
import { UserContext } from '../../../providers/UserProvider'
import { MollieMethod } from '../../../plugins/middleware-api-client'
import { Trans, useTranslation } from 'react-i18next'

interface PaymentMethodProps {
    paymentMethod: MollieMethod
}

const PaymentMethodComponent: React.FC<PaymentMethodProps> = ({
    paymentMethod,
}) => {
    const { t } = useTranslation()
    const userContext = useContext(UserContext)
    const [enabled, setEnabled] = useState<boolean>(false)
    const [statusColor, setStatusColor] = useState<string>(
        'bg-brand-primary border-brand-primary',
    )
    const [isEnabling, setIsEnabling] = useState<boolean>(false)

    useEffect(() => {
        switch (paymentMethod.status) {
            case 'activated':
                setEnabled(true)
                setStatusColor('bg-brand-primary border-brand-primary')
                break
            case 'pending-boarding':
            case 'pending-review':
            case 'pending-external':
                setEnabled(true)
                setStatusColor('bg-brand-yellow border-brand-yellow')
                break
            default:
                setEnabled(false)
                break
        }
    }, [paymentMethod])

    const postEnabledState = async (radioEnabled: boolean) => {
        if (isEnabling) return
        try {
            setIsEnabling(true)
            let result = await userContext.togglePaymentMethod(
                radioEnabled,
                paymentMethod.id,
            )
            if (result) {
                // setEnabled(radioEnabled)
                await userContext.getPaymentMethods(
                    t('payment:default-settings'),
                )
                // TODO: Success popup
                setIsEnabling(false)
            }
        } catch (e) {
            setIsEnabling(false)
            // TODO: Show error popup
            console.log('PaymentMethod was not updated')
        }
    }

    return (
        <div className="flex-grow border border-brand-gray-200 px-3 py-2 bg-white mb-4 rounded">
            <div className="flex justify-between py-2">
                <div className="flex items-center">
                    <div className="rounded h-8 w-8 flex shrink">
                        <img
                            className="m-auto"
                            src={paymentMethod.image}
                            alt={paymentMethod.name}
                        />
                    </div>
                    <div className="pl-3">
                        <Heading
                            headingVariant={HeadingVariant.BLACK}
                            level={Level.HEADING_6}
                        >
                            {paymentMethod.name}
                        </Heading>
                    </div>
                </div>
                <div className="flex items-center">
                    {!!paymentMethod.status && paymentMethod.id === 'paypal' && (
                        <div className="text-center items-center px-4 py-2 border border-transparent text-sm">
                            <Trans
                                i18nKey="account:methods.external-payment"
                                components={[
                                    <a
                                        target="_blank"
                                        className="underline"
                                        href="https://mollie.com/dashboard"
                                    >
                                        Mollie.com
                                    </a>,
                                ]}
                            />
                        </div>
                    )}
                    <Radio
                        enabled={enabled}
                        setEnabled={postEnabledState}
                        statusColor={statusColor}
                        loading={isEnabling}
                    />
                </div>
            </div>
        </div>
    )
}

export default PaymentMethodComponent

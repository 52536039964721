import React, { useContext } from 'react'
import Heading, { HeadingVariant, Level } from '../Typography/Heading'
import { Spin as Hamburger } from 'hamburger-react'
import { LayoutContext } from '../../providers/LayoutProvider'

const Header: React.FC = ({ children }) => {
    const layoutContext = useContext(LayoutContext)
    return (
        <header className="bg-white flex justify-between items-center">
            <Heading
                level={Level.HEADING_2}
                headingVariant={HeadingVariant.BLACK}
                classList="px-8 py-6"
            >
                {children}
            </Heading>
            <div className="-mr-2 -my-2 md:hidden px-8">
                <button
                    onClick={() =>
                        layoutContext.setIsMenuOpen(!layoutContext.isMenuOpen)
                    }
                >
                    <span className="sr-only">Open menu</span>
                    <Hamburger />
                </button>
            </div>
        </header>
    )
}

export default Header

import { useTranslation } from 'react-i18next'

interface TestModeProps {
    testMode: boolean
    setTestMode: (testMode: boolean) => void
}

const TestMode: React.FC<TestModeProps> = ({ testMode, setTestMode }) => {
    const { t } = useTranslation()

    return (
        <>
            {testMode && (
                <div className="bg-yellow-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <svg
                                className="h-5 w-5 text-yellow-400"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm0 2a10 10 0 100-20 10 10 0 000 20z"
                                    clipRule="evenodd"
                                />
                                <path
                                    fillRule="evenodd"
                                    d="M10 5a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                                    clipRule="evenodd"
                                />
                                <path
                                    fillRule="evenodd"
                                    d="M10 15a1 1 0 100-2 1 1 0 000 2z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </div>
                        <div className="ml-3 flex justify-between w-full">
                            <div>
                                <p className="text-sm font-medium text-yellow-800">
                                    {t(
                                        'account:test-mode-banner.test-mode-active',
                                    )}
                                </p>
                                <div className="mt-2 text-sm text-yellow-700">
                                    <p>
                                        {t(
                                            'account:test-mode-banner.test-mode-active-description',
                                        )}
                                    </p>
                                </div>
                            </div>
                            <div className="mt-2">
                                <button
                                    onClick={() => setTestMode(false)}
                                    type="button"
                                    className="bg-yellow-50 border border-transparent rounded-md py-1.5 px-4 inline-flex text-sm font-medium text-yellow-700 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 whitespace-nowrap"
                                >
                                    {t(
                                        'account:test-mode-banner.disable-test-mode',
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default TestMode

const locale_lang = {
    en_US: 'en',
    en_GB: 'en',
    nl_NL: 'nl',
    nl_BE: 'nl',
    fr_FR: 'fr',
    fr_BE: 'fr',
    de_DE: 'de',
    de_AT: 'de',
    de_CH: 'de',
    es_ES: 'es',
    pt_PT: 'pt',
    it_IT: 'it',
    nb_NO: 'nb',
    sv_SE: 'sv',
    fi_FI: 'fi',
    da_DK: 'dk',
    is_IS: 'is',
    hu_HU: 'hu',
    pl_PL: 'pl',
    lv_LV: 'lv',
    lt_LT: 'lt',
}

export default {
    getLocale(lang?: string | null): string {
        return (
            Object.entries(locale_lang).find((l) => l[1] === lang)?.[0] ||
            'en_US'
        )
    },

    getLang(locale?: string | null): string {
        return (
            Object.entries(locale_lang).find((l) => l[0] === locale)?.[1] ||
            'en'
        )
    },
}

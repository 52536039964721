import React from 'react'

interface ILabelProps {
    id?: string
    classList?: string
}

const Label: React.FC<ILabelProps> = ({ classList, children, id }) => {
    if (!classList) classList = 'mb-2 block text-sm font-medium text-gray-600'
    return (
        <label className={classList} htmlFor={id}>
            {children}
        </label>
    )
}

export default Label

import React, { createContext, useState } from 'react'
interface PaymentMethodProps {
    setOpenId: (id: string) => void
    getId: () => string
    isOpenId: (id: string) => boolean
}

export const PaymentMethodContext = createContext({} as PaymentMethodProps)

const PaymentMethodProvider: React.FC = ({ children }) => {
    const [internalId, setId] = useState<string>('')

    const setOpenId = (id: string) => {
        setId(id)
    }

    const getId = () => {
        return internalId
    }

    const isOpenId = (id: string) => {
        return internalId == id
    }

    return (
        <PaymentMethodContext.Provider
            value={{
                setOpenId,
                getId,
                isOpenId,
            }}
        >
            {children}
        </PaymentMethodContext.Provider>
    )
}

export default PaymentMethodProvider

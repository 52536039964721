import React from 'react'

const SomethingWentWrong: React.FC = () => {
    return (
        <div className="flex flex-col min-h-screen max-w-md p-6 justify-center m-auto">
            <p className="font-base text-base text-lg text-black text-opacity-60 text-center">
                Something went wrong!
            </p>

            {/*<p className="font-base text-base text-lg text-black text-opacity-60 text-center mt-4">*/}
            {/*  Please try again*/}
            {/*</p>*/}
        </div>
    )
}

export default SomethingWentWrong

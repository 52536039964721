import React, { createContext, useContext, useState } from 'react'
import { UserContext } from './UserProvider'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

interface LayoutContextProps {
    isMenuOpen: boolean
    setIsMenuOpen: (isMenuOpen: boolean) => void
    showAlertModal: boolean
    setShowAlertModal: (showAlertModal: boolean) => void
    disconnect: () => void
}

export const LayoutContext = createContext({} as LayoutContextProps)

const LayoutProvider: React.FC = ({ children }) => {
    const userContext = useContext(UserContext)

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
    const [showAlertModal, setShowAlertModal] = useState<boolean>(false)
    const disconnect = async () => {
        let response = await userContext.disconnectMollie()
        if (response) {
            setShowAlertModal(false)
        }
    }
    return (
        <LayoutContext.Provider
            value={{
                isMenuOpen,
                setIsMenuOpen,
                showAlertModal,
                setShowAlertModal,
                disconnect,
            }}
        >
            <ToastContainer
                position="top-right"
                autoClose={3000}
                newestOnTop={false}
                closeOnClick
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover={false}
            />
            {children}
        </LayoutContext.Provider>
    )
}

export default LayoutProvider

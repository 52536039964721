import React, {useContext, useEffect, useState} from 'react'
import Header from '../../components/Header/Header'
import Heading, {
  HeadingVariant,
  Level,
} from '../../components/Typography/Heading'
import Button, {Action} from '../../components/Button/Button'
import {UserContext} from '../../providers/UserProvider'
import {useNavigate} from 'react-router-dom'
import {installationService} from '../../plugins/axios'
import {toast} from 'react-toastify'
import {Trans, useTranslation} from 'react-i18next'

const Connect: React.FC = () => {
  const {t} = useTranslation()
  const userContext = useContext(UserContext)
  const navigate = useNavigate()
  const [isConnecting, setIsConnecting] = useState<boolean>(false)

  // TODO: Fix in userProvider & App.tsx
  useEffect(() => {
    // if (userContext.merchant?.active_mollie_organization) {
    //     navigate('/')
    // }
    console.log(userContext.merchant?.active_mollie_organization)
  }, [userContext.merchant])

  const ConnectToMollie = async () => {
    setIsConnecting(true)
    try {
      const response = await installationService.connectMollie({
        headers: {
          Authorization: `Bearer ${userContext.token}`,
        },
      })
      toast.success(t('connect:successfully-connected'))
      window.location.href = response.data.redirect_url
      setIsConnecting(false)
    } catch (e) {
      setIsConnecting(false)
      toast.error(t('connect:error-connecting'))
      // TODO: error popup
    }
  }

  return (
    <>
      <div className="flex flex-col min-h-screen">
        <Header>&nbsp;</Header>
        <div className="flex flex-col min-h-screen justify-center items-center content-center text-center">
          <div className="max-w-xs">
            <Heading
              headingVariant={HeadingVariant.BLACK}
              level={Level.HEADING_2}
            >
              {t('connect:connect-mollie')}
            </Heading>
            <div className="py-6 mb-2">
              <span>{t('connect:connect-mollie-info')}</span>
            </div>
            <Button
              onClick={ConnectToMollie}
              loading={isConnecting}
            >
              {t('connect:connect-button')}
            </Button>
          </div>
          {userContext.merchant?.active_mollie_organization && (
            <div className="max-w-xs border-t border-gray-200 mt-8">
              <div className="py-6 mb-2">
                <Trans
                  i18nKey="connect:already-connected"
                  values={{ organizationName: userContext.merchant?.active_mollie_organization }}
                  components={{ bold: <strong /> }}
                />
              </div>
              <Button
                action={Action.BLUE}
                inverted={true}
                onClick={() => navigate('/')}
              >
                {t('connect:back-to-dashboard-button')}
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Connect

import React, { useContext, useEffect } from 'react'
import Heading, { Level } from '../../components/Typography/Heading'
import { UserContext } from '../../providers/UserProvider'
import PaymentMethodComponent from './components/PaymentMethod'
import { useTranslation } from 'react-i18next'
import PlaceholderPaymentMethod from '../../components/PlaceholderPaymentMethod'

const MolliePaymentMethods: React.FC = () => {
    const { t } = useTranslation()
    const userContext = useContext(UserContext)

    useEffect(() => {
        userContext.setPaymentMethods([])
        userContext.getPaymentMethods(t('payment:default-settings'))
    }, [])
    return (
        <>
            <div className="flex flex-col min-h-screen mt-16">
                <Heading headingVariant={4} level={Level.HEADING_4} tag="h3">
                    {t('payment:payment-methods-mollie')}
                </Heading>
                <div className="pt-3">
                    <p>{t('payment:payment-methods-mollie-text')}</p>
                </div>
                <div className="grid grid-cols-8">
                    <div className="col-span-6">
                        <div className="pt-4">
                            <div className="grid grid-cols-12">
                                {userContext.paymentMethods.length > 0 ? (
                                    <div className="col-span-12">
                                        {/* //TODO: Remove temporary `.filter(method => method.id !== "voucher")` when vouchers are resolved! */}
                                        {userContext.paymentMethods
                                            .filter(
                                                (method) =>
                                                    method.id !== 'voucher',
                                            )
                                            .map((paymentMethod, index) => {
                                                return (
                                                    <PaymentMethodComponent
                                                        key={index}
                                                        paymentMethod={
                                                            paymentMethod
                                                        }
                                                    />
                                                )
                                            })}
                                    </div>
                                ) : (
                                    <div className="col-span-12">
                                        {' '}
                                        {[...Array(10)].map((_, index) => (
                                            <PlaceholderPaymentMethod
                                                key={`placeholder-index-${index}`}
                                            />
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MolliePaymentMethods

import React from 'react'
import Label from './Label'
import { useTranslation } from 'react-i18next'

export enum InputType {
    TEXT,
    FILE,
    // TEXT_SM
}

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string
    error?: boolean
    inputType?: InputType
}

const Input: React.FC<TextInputProps> = ({
    label,
    inputType,
    id,
    error = false,
    ...rest
}) => {
    const { t } = useTranslation()
    if (!inputType) inputType = InputType.TEXT
    return (
        <>
            {label && (
                <Label
                    classList="m-0 w-full lg:w-1/2 font-medium text-sm text-gray-600"
                    id={id}
                >
                    {label}
                </Label>
            )}
            {inputType === InputType.TEXT && (
                <div className="w-full lg:w-1/2">
                    <input
                        id={id}
                        type="text"
                        {...rest}
                        className={`block py-2 px-3 w-full rounded-3px border text-sm leading-4 ${
                            error
                                ? 'border-red-800'
                                : 'border-black border-opacity-10'
                        } appearance-none focus:outline-none focus:border-black focus:border-opacity-10 focus:ring-black focus:ring-opacity-20 placeholder-brand-gray-700 placeholder-opacity-40`}
                    />
                </div>
            )}
            {inputType === InputType.FILE && (
                <div className="w-full lg:w-1/2">
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <div className="max-w-lg flex justify-center bg-brand-primary bg-opacity-10 px-6 py-5 border-2 border-brand-primary border-dashed rounded-md">
                            <div className="space-y-1 text-center">
                                <div className="flex text-xs text-brand-gray-600 leading-3">
                                    <p className="pr-1">
                                        {t('payment:settings.drag-drop')}
                                    </p>
                                    <label
                                        htmlFor={id}
                                        className="relative cursor-pointer rounded-md font-medium text-brand-primary underline hover:text-brand-primary-600"
                                    >
                                        <span>
                                            {t('payment:settings.browse')}
                                        </span>
                                        <input
                                            {...rest}
                                            id={id}
                                            name={id}
                                            type="file"
                                            className="sr-only"
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Input

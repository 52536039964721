import { ConfigurationApi, InstallationApi } from './middleware-api-client'

const browserWindow: any = window

export const configurationService = new ConfigurationApi({
    accessToken: undefined,
    apiKey: undefined,
    baseOptions: {
        headers: {
            'Content-Type': 'application/json',
        },
    },
    basePath: process.env.REACT_APP_MIDDLEWARE_URL || browserWindow.API_URL,
    formDataCtor: undefined,
    password: '',
    username: '',
    isJsonMime(mime: string): boolean {
        return false
    },
})

export const installationService = new InstallationApi({
    accessToken: undefined,
    apiKey: undefined,
    baseOptions: {
        headers: {
            'Content-Type': 'application/json',
        },
    },
    basePath: process.env.REACT_APP_MIDDLEWARE_URL || browserWindow.API_URL,
    formDataCtor: undefined,
    password: '',
    username: '',
    isJsonMime(mime: string): boolean {
        return false
    },
})

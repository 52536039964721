import React from 'react'
import { Listbox } from '@headlessui/react'
import Label from './Label'
import { useTranslation } from 'react-i18next'

interface SelectProps extends React.InputHTMLAttributes<HTMLSelectElement> {
    label?: string
}

const PlaceholderSelect: React.FC<SelectProps> = ({ label, ...rest }) => {
    const disabled: boolean =
        rest.disabled !== undefined ? rest.disabled : false
    const { t } = useTranslation()
    const setSelected = () => {}
    return (
        <Listbox value="" disabled={disabled} onChange={setSelected}>
            <div className="relative animate-pulse">
                {label && (
                    <Label id="test" classList="text-gray-500">
                        {label}
                    </Label>
                )}
                <Listbox.Button className="block py-3 pl-3 pr-7 w-full rounded-2px relative border border-black border-opacity-10 appearance-none focus:outline-none focus:border-opacity-20 placeholder-brand-gray-700 placeholder-opacity-40 truncate min-h-12.25 bg-white">
                    <span className="block text-left text-sm leading-3 text-gray-700">
                        {t('common:loading')}
                    </span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none text-black">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-3 w-3"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M19 9l-7 7-7-7"
                            />
                        </svg>
                    </span>
                </Listbox.Button>
            </div>
        </Listbox>
    )
}

export default PlaceholderSelect

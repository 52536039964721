import React, { Suspense, useContext } from 'react'
import {
    BrowserRouter,
    Route,
    Navigate,
    Routes,
    Outlet,
} from 'react-router-dom'

// import AppLayout from "./layouts/AppLayout/AppLayout";
import UserProvider, { UserContext } from './providers/UserProvider'
import Connect from './pages/Connect/Connect'
import Organisation from './pages/Organisation/Organisation'
import GeneralSettings from './pages/GeneralSettings/GeneralSettings'
import PaymentMethods from './pages/PaymentMethods/PaymentMethods'
import LayoutProvider from './providers/LayoutProvider'
import Disconnected from './pages/Disconnected/Disconnected'
import SomethingWentWrong from './pages/SomethingWentWrong/SomethingWentWrong'
import Loading from './pages/Loading/Loading'
import Processing from './pages/Processing/Processing'
import Unauthorized from './pages/Unauthorized/Unauthorized'

const ProtectedRoute: React.FC = () => {
    const userContext = useContext(UserContext)
    if (userContext.loaded) {
        return !userContext.token ? <Navigate to="/unauthorized" /> : <Outlet />
    }
    return <Loading />
}

const MerchantHasOrganization: React.FC = () => {
    const userContext = useContext(UserContext)
    if (userContext.loaded) {
        return !userContext.merchant?.active_mollie_organization ? (
            <Navigate to="/connect" />
        ) : (
            <Outlet />
        )
    }
    return <Loading />
}

const HasSelectedProfile: React.FC = () => {
    const userContext = useContext(UserContext)
    if (userContext.loaded) {
        return !userContext.merchant?.active_mollie_profile_id ? (
            <Navigate to="/profile" />
        ) : (
            <Outlet />
        )
    }
    return <Loading />
}

function App() {
    const AppLayout = React.lazy(() => import('./layouts/AppLayout/AppLayout'))
    return (
        <Suspense fallback={<Loading />}>
            <BrowserRouter>
                <UserProvider>
                    <LayoutProvider>
                        <Routes>
                            <Route
                                path="/processing"
                                element={<Processing />}
                            />
                            <Route element={<AppLayout />}>
                                <Route element={<ProtectedRoute />}>
                                    <Route
                                        path="/connect"
                                        element={<Connect />}
                                    />
                                    <Route
                                        element={<MerchantHasOrganization />}
                                    >
                                        <Route
                                            path="/profile"
                                            element={<Organisation />}
                                        />
                                        <Route element={<HasSelectedProfile />}>
                                            <Route
                                                index
                                                element={<GeneralSettings />}
                                            />
                                            <Route
                                                path="/payment-methods"
                                                element={<PaymentMethods />}
                                            />
                                        </Route>
                                    </Route>
                                </Route>
                                <Route
                                    path="/unauthorized"
                                    element={<Unauthorized />}
                                />
                                <Route
                                    path="/error"
                                    element={<SomethingWentWrong />}
                                />
                                <Route
                                    path="/disconnected"
                                    element={<Disconnected />}
                                />
                            </Route>
                        </Routes>
                    </LayoutProvider>
                </UserProvider>
            </BrowserRouter>
        </Suspense>
    )
}

export default App
